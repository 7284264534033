import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrlEmployee: "http://5.5.5.10:4320/api",
    // apiUrlRegistration: "http://5.5.5.10:4300/api",
    apiUrlEmployee: "https://employee.janat-albelad.com/api",
    apiUrlRegistrationOld: "https://student.janat-albelad.com/api",
    apiUrlRegistration: "https://student.janat-albelad.com/api",
    sections: null,
    showDialog: false,
    selectedStudent: null,
    acceptedTypes: null,
    yearStudies: null,
    studentStatus: null,
    allCertificateStatus: null,
    passTypes: null,
    imageTypes: null,
    provinces: null,
    studyCategories: null,
    studySubCategories: null,
    studentStep: 1,
    newStudentId: null,
    orderTitles: null,
    studentSelectedList: [],
    genders: [{ text: "ذكر" }, { text: "انثى" }],
    studyTypes: [
      { text: "صباحي", value: 1 },
      { text: "مسائي", value: 2 },
    ],
    levels: [
      { text: "المرحلة الاولى", value: 1 },
      { text: "المرحلة الثانية", value: 2 },
      { text: "المرحلة الثالثة", value: 3 },
      { text: "المرحلة الرابعة", value: 4 },
      { text: "المرحلة الخامسة", value: 5 },
    ],

    levelsWithGraduation: [
      { text: "المرحلة الاولى", value: 1 },
      { text: "المرحلة الثانية", value: 2 },
      { text: "المرحلة الثالثة", value: 3 },
      { text: "المرحلة الرابعة", value: 4 },
      { text: "المرحلة الخامسة", value: 5 },
      { text: "متخرج", value: 10 },
    ],
    user: null,
    userData: null,
  },
  getters: {},
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("register");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
